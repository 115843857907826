@font-face {
  font-family: 'FloridaVibes';
  src: url('../public/assets/fonts/Florida.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: 'FloridaVibes', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

